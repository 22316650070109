import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Collapse,
  CssBaseline,
  Drawer,
  Fade,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Modal,
  Select,
  styled,
  Tab,
  TableBody,
  TableContainer,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import SupervisorSidebar from "./sidebar";
import PropTypes from "prop-types";
import {
  AccountCircle,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useAuth } from "../authContext";
import axios from "axios";
import { NumericFormat } from "react-number-format";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FE8A01 !important", // Indicator color with !important
  },
  "& .MuiTab-root": {
    color: "#000000 !important", // Default text color with !important
    textTransform: "none !important", // Remove uppercase text transformation with !important
  },
  "& .Mui-selected": {
    color: "#FE8A01 !important", // Color for selected tab text with !important
  },
});

const idrFormat = new Intl.NumberFormat("en-ID", {
  style: "currency",
  currency: "IDR",
});

const formatCurrency = (number) => {
  if (!number) number = 0;
  return idrFormat.format(number);
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const headCells = [
  { id: "phoneNumber", numeric: false, disablePadding: false, label: "Produk" },
  { id: "createdDate", numeric: false, disablePadding: false, label: "Tanggal" },

  { id: "price", numeric: true, disablePadding: false, label: "Total Pesanan" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "delivery",
    numeric: false,
    disablePadding: false,
    label: "Jasa Kirim",
  },
  { id: "action", numeric: false, disablePadding: false, label: "Aksi" },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: "#FE8A01" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Employee Data
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function PemesananPage2() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const cleanedBackendUrl = backendUrl.replace("/backend", "");

  const drawerWidth = 300;
  const { auth, logout } = useAuth();
  const getusername = auth.user ? auth.user.username : "";
  const [openLogout, setOpenLogout] = useState(false);
  const [page, setPage] = useState("main");
  const [ShowSuccess, setShowSuccess] = useState(false);
  const [MsgInsert, setMsgInsert] = useState("");
  const [msgError, setMsgError] = useState("");
  const [showError, setShowError] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("tanggal");
  var visibleRows = [];
  const [filteredOrderData, setFilteredOrderData] = useState(visibleRows);
  const [ColourData, setColourData] = useState([]);
  const [hexcode, setHexcode] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [DataTipe, setDataTipe] = useState([]);
  const [TypeCode, setTypeCode] = useState([]);
  const [Orderid, setOrderid] = useState([]);
  const [OrderidDetail, setOrderidDetail] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openRows, setOpenRows] = useState({});

  const handleRowToggle = (index) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the specific row's expand state
    }));
  };

  const [statusDetail, setStatusDetail] = useState(
    orderDetail.map((detail) => detail.status.key)
  );
  const [deleteRow, setDeleteRow] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    if (typeof property === 'object' && property !== null) {
      setOrderBy(property?.label);
    } else {
      setOrderBy(property);
    }
  };

  visibleRows = React.useMemo(() => {
    if (!filteredOrderData) {
      return [];
    }
    const data = filteredOrderData;

    // Apply search filter before sorting
    const filteredData = data.filter((row) =>
      Object.values(row).some((val) => {
        if (typeof val === 'object' && val !== null) {
          return String(val?.label).toLowerCase().includes(searchTerm.toLowerCase());
        } else {
          return String(val).toLowerCase().includes(searchTerm.toLowerCase());
        }
      })
    );

    // Sort the filtered data (assuming getComparator is a valid sorting function)
    return filteredData.sort(getComparator(order, orderBy));
  }, [filteredOrderData, searchTerm, order, orderBy]);
  const handleLogout = () => {
    setOpenLogout(false);
    logout();
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [open, setOpen] = React.useState(false);

  const handleOpenDelete = (row) => {
    setDeleteRow(row);
    console.log("Deleted row:", row);
    setOpenDelete(true);
  };

  const handleConfirmDelete = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${cleanedBackendUrl}/orders/${orderDetail[deleteRow].id}`
      );
      console.log(response.data);
      setShowSuccess(true);
      setMsgInsert("Berhasil Hapus Order");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
      toMain();
    } catch (error) {
      console.error(error);
      setMsgError("Gagal Hapus Order");
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
    setOpenDelete(false);
  };

  const handleCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);

    // Hide the notification after 3 seconds
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const handlePopperClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleNavigation = (nav) => {
    console.log("Navigating to status:", nav);
    if (nav === "SEMUA") {
      setFilteredOrderData(orderData);
      console.log("Filtered Orders:", filteredOrderData);
    } else {
      const filteredOrders = orderData.filter(
        (order) => order.status.key === nav
      );
      console.log("Filtered Orders:", filteredOrders);
      setFilteredOrderData(filteredOrders);
    }
  };

  const handleSubmit = async () => {
    console.log(orderDetail);

    try {
      for (let i = 0; i < orderDetail.length; i++) {
        // Prepare the data for the item update
        const putItemData = {
          id: orderDetail[i].id,
          phoneNumber: orderDetail[i].phoneNumber,
          itemList: [
            {
              typeCode: TypeCode[i], // Corresponds to the same index in TypeCode
            },
          ],
          price: orderDetail[i].price,
        };

        console.log("Item Data:", putItemData);

        // Send the PUT request to update the item details
        const itemResponse = await axios.put(
          `${cleanedBackendUrl}/orders/${orderDetail[i].id}/items`,
          putItemData
        );

        console.log("Item Response:", itemResponse);

        // Prepare the data for the status update
        const putStatusData = {
          id: orderDetail[i].id,
          status: statusDetail[i], // Corresponds to the same index in statusDetail
        };

        console.log("Status Data:", putStatusData);

        // Send the PUT request to update the status
        const statusResponse = await axios.put(
          `${cleanedBackendUrl}/orders/${orderDetail[i].id}/status`,
          putStatusData
        );

        console.log("Status Response:", statusResponse);
      }

      setShowSuccess(true);
      setMsgInsert("Berhasil Mengupdate Pemesanan");
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    } catch (error) {
      setShowError(true);
      console.error("Error updating orders:", error);
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    } finally {
      toMain();
    }
  };

  const toMain = () => {
    fetchOrders();
    fetchItems();
    fetchStatus();
    setValue(0);
    handleNavigation("SEMUA");
    setPage("main");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toEdit = async (id) => {
    fetchDataType();
    fetchDataColour();
    try {
      const selectedOrder = filteredOrderData[id];
      const selectedPhoneNumber = selectedOrder.phoneNumber;

      // Fetch orders with the same phone number
      const response = await axios.get(
        `${cleanedBackendUrl}/orders?phone-number=${selectedPhoneNumber}`
      );
      console.log(response.data);

      const filteredOrders = response.data.orderList.filter(
        (order) =>
          order.status.key === "DRAFT" ||
          order.status.key === "WAITING_FOR_PAYMENT"
      );

      setOrderDetail(filteredOrders);
      setOrderidDetail(Orderid[id]);
      console.log(id, page);
      setPage("edit");
    } catch (error) {
      console.error("Error fetching order data:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (orderDetail && orderDetail.length > 0) {
      const hexCodes = orderDetail.map((detail) => {
        const firstLetter = detail.id.charAt(0);
        const matchedColor = ColourData.find(
          (color) => color.code === firstLetter
        );
        return matchedColor ? matchedColor.hexCode : null;
      });

      setHexcode(hexCodes);
    }
  }, [orderDetail, ColourData]);

  const handleIdChange = (e, index) => {
    const newOrderDetail = [...orderDetail]; // Create a copy of the orderDetail array
    newOrderDetail[index] = {
      ...newOrderDetail[index],
      id: e.target.value, // Update the id of the specific item
    };
    setOrderDetail(newOrderDetail); // Update the state with the modified array
  };

  const handlePhoneNumberChange = (e) => {
    const newOrderDetail = [...orderDetail]; // Create a copy of the orderDetail array
    newOrderDetail[0] = {
      ...newOrderDetail[0],
      phoneNumber: e.target.value, // Update the phoneNumber of the first item
    };
    setOrderDetail(newOrderDetail); // Update the state with the modified array
  };

  const handlePriceChange = (values, index) => {
    setOrderDetail((prevOrderDetail) => {
      const updatedOrderDetail = [...prevOrderDetail]; // Copy the existing array
      updatedOrderDetail[index] = {
        ...updatedOrderDetail[index],
        price: values.floatValue, // Update the price of the specific item
      };
      return updatedOrderDetail; // Return the updated array
    });
  };

  const handleTypeChange = (event, newValue, index) => {
    setTypeCode((prevTypeCode) => {
      const updatedTypeCode = [...prevTypeCode];

      // If the index is valid and within bounds, update the specific index
      if (index >= 0) {
        // Ensure the array has enough length to accommodate the index
        while (updatedTypeCode.length <= index) {
          updatedTypeCode.push(null); // Add null values until the array is long enough
        }

        // Update the specific index with the new value (or null if newValue is empty)
        updatedTypeCode[index] = newValue ? newValue.value : null;
      }

      return updatedTypeCode; // Return the updated array
    });
  };

  const fetchDataType = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/items/types`);
      console.log(response.data.itemTypeList);
      setDataTipe(response.data.itemTypeList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataColour = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders/colors`);
      console.log(response.data.orderColorList);
      setColourData(response.data.orderColorList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDataCode = () => {};

  const optTipe = DataTipe.map((item) => ({
    id: item.id,
    name: item.name,
    varian: item.variant,
    value: item.code,
  }));

  const statusOptions = [
    { key: "DRAFT", label: "DRAFT" },
    { key: "WAITING_FOR_PAYMENT", label: "MENUNGGU PEMBAYARAN" },
    // { key: 'PAID', label: 'Paid' },
    // { key: 'SHIPPED', label: 'Shipped' },
    // { key: 'DELIVERED', label: 'Delivered' },
    // { key: 'CANCELED', label: 'Canceled' },
    // Add more statuses as needed
  ];

  const handleChangeStatus = (event, index) => {
    const newStatusKey = event.target.value;

    // Update the statusDetail array with the new value
    setStatusDetail((prevStatusDetail) => {
      const updatedStatusDetail = [...prevStatusDetail];
      updatedStatusDetail[index] = newStatusKey;
      return updatedStatusDetail;
    });
  };

  useEffect(() => {
    console.log("Order Detail", orderDetail);

    if (orderDetail && orderDetail.length > 0) {
      // Initialize TypeCode if not already initialized
      if (TypeCode.length === 0) {
        const newTypeCodes = orderDetail.map((detail) => {
          console.log(
            "detail itemList typeCode:",
            detail.itemList[0]?.typeCode
          ); // Debug
          return detail.itemList[0]?.typeCode || null;
        });
        setTypeCode(newTypeCodes);
        console.log("TypeCode initialized:", newTypeCodes); // Debug TypeCode initialization
      }

      // Initialize statusDetail if not already initialized
      if (statusDetail.length === 0) {
        const initialStatusDetail = orderDetail.map(
          (detail) => detail.status.key
        );
        setStatusDetail(initialStatusDetail);
        console.log("statusDetail initialized:", initialStatusDetail); // Debug statusDetail initialization
      }
    }
    console.log("optTipe:", optTipe);
  }, [orderDetail]);

  useEffect(() => {
    console.log("DataTipe", DataTipe);
  }, [DataTipe]);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/items`);
      console.log(response.data.itemList);
      setItemData(response.data.itemList);
    } catch (error) {
      console.error("Error fetching item list:", error.response.data.itemList);
    }
  };
  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders`);
      console.log(response.data.orderList);
      setOrderData(response.data.orderList);
    } catch (error) {
      console.error(
        "Error fetching order list:",
        error.response.data.orderList
      );
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await axios.get(`${cleanedBackendUrl}/orders/status`);
      console.log(response.data.orderStatusList);
      setOrderStatus(response.data.orderStatusList);
    } catch (error) {
      console.error(
        "Error fetching order list:",
        error.response.data.orderList
      );
    }
  };

  useEffect(() => {
    console.log(cleanedBackendUrl);
    fetchOrders();
    fetchItems();
    fetchStatus();
  }, []);

  useEffect(() => {
    console.log("Updated orderData:", orderData);

    console.log("Updated itemData:", itemData);
    console.log("Updated orderStatus:", orderStatus);
    setFilteredOrderData(orderData);
  }, [orderData, itemData, orderStatus]);

  useEffect(() => {
    const slicedIds = filteredOrderData.map((order) => order.id.slice(1, 4));
    console.log("Sliced IDs Array:", slicedIds);
    setOrderid(slicedIds);
  }, [filteredOrderData]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "black",
              color: "white",
            },
          }}
          open
        >
          <SupervisorSidebar />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc((100% - ${drawerWidth}px))` },
        }}
      >
        <Button
          style={{ float: "right" }}
          color="inherit"
          onClick={handleOpenLogout}
          startIcon={<AccountCircle />}
        >
          {getusername}
        </Button>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openLogout}
          onClose={handleCloseLogout}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openLogout}>
            <Box sx={styleModal}>
              <AccountCircle style={{ fontSize: 100 }} />
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah anda yakin ingin keluar?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={handleLogout}>
                  Ya
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseLogout}
                  sx={{ ml: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Tidak
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openDelete}
          onClose={handleCloseDelete}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              TransitionComponent: Fade,
            },
          }}
        >
          <Fade in={openDelete}>
            <Box sx={styleModal}>
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Apakah kamu yakin ingin membuang data ini?
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleConfirmDelete}
                  sx={{ mr: 2, backgroundColor: "#FE8A01", color: "white" }}
                >
                  Ya
                </Button>
                <Button variant="outlined" onClick={handleCloseDelete}>
                  Tidak
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <br></br>
        {ShowSuccess && (
          <Alert
            variant="filled"
            severity="success"
            style={{ marginBottom: 3 }}
          >
            {MsgInsert}
          </Alert>
        )}
        {showError && (
          <Alert variant="filled" severity="error" style={{ marginBottom: 3 }}>
            {msgError}
          </Alert>
        )}
        <Grid
          container
          sx={{
            minWidth: 300,
            width: "100%",
            paddingTop: "24px",
            rowGap: "24px",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h3">Pemesanan</Typography>
          </Grid>
        </Grid>
        <TableContainer sx={{ maxHeight: "100%", maxWidth: "100%" }}>
          {page === "main" ? (
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                sx={{
                  minWidth: 300,
                  width: "100%",
                  paddingTop: "24px",
                  rowGap: "24px",
                }}
              >
                <Grid item xs={3} sx={{ paddingRight: "24px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Cari"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{ paddingLeft: 0 }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  paddingTop: "24px",
                }}
              >
                <AntTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="secondary"
                  indicatorColor="secondary"
                >
                  <Tab
                    label="SEMUA"
                    {...a11yProps(0)}
                    onClick={() => handleNavigation("SEMUA")}
                  />
                  {orderStatus.map((status, index) => (
                    <Tab
                      key={status.key}
                      label={status.label}
                      {...a11yProps(index + 1)}
                      onClick={() => handleNavigation(status.key)}
                    />
                  ))}
                </AntTabs>
              </Box>
              <Grid container sx={{ minWidth: 300, width: "100%" }}>
                <Grid item xs={12}>
                  <Typography>{visibleRows.length} Pesanan</Typography>
                </Grid>
              </Grid>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {Array(visibleRows.length)
                    .fill()
                    .map((_, indexX) => (
                      <>
                        {/* return ( */}
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={indexX}
                          sx={{
                            cursor:
                              visibleRows[indexX].status.key === "DRAFT" ||
                              visibleRows[indexX].status.key ===
                                "WAITING_FOR_PAYMENT"
                                ? "pointer"
                                : "default",
                            "&:hover":
                              visibleRows[indexX].status.key === "DRAFT" ||
                              visibleRows[indexX].status.key ===
                                "WAITING_FOR_PAYMENT"
                                ? {
                                    backgroundColor: "#f0f0f0",
                                  }
                                : {},
                          }}
                          onClick={() => {
                            if (
                              visibleRows[indexX].status.key === "DRAFT" ||
                              visibleRows[indexX].status.key ===
                                "WAITING_FOR_PAYMENT"
                            ) {
                              toEdit(indexX);
                            }
                          }}
                        >
                          <TableCell align="center">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleRowToggle(indexX)}
                            >
                              {openRows[indexX] ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                            <AccountCircle /> {visibleRows[indexX]?.phoneNumber}
                          </TableCell>
                          <TableCell align="center">
                            {visibleRows[indexX]?.createdDate}
                          </TableCell>
                          <TableCell align="center">
                            {formatCurrency(visibleRows[indexX]?.price)}
                          </TableCell>
                          <TableCell align="center">
                            {visibleRows[indexX]?.status?.label}
                          </TableCell>
                          <TableCell align="center">
                            No. Resi{" "}
                            {visibleRows[indexX]?.deliveryReceiptNumber}
                          </TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}
                          >
                            <Collapse
                              in={openRows[indexX]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                {Array(visibleRows[indexX].itemList.length)
                                  .fill()
                                  .map((_, indexY) => (
                                    <Grid
                                      container
                                      sx={{
                                        borderBottom: "1px solid grey",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={5}
                                        sx={{
                                          padding: "12px",
                                        }}
                                      >
                                        {visibleRows[indexX].itemList[indexY]
                                          ?.typeCode || "-"}{" "}
                                        ({Orderid[indexX]}) -{" "}
                                        {visibleRows[indexX].itemList[indexY]
                                          ?.typeVariant || "-"}{" "}
                                        - {visibleRows[indexX]?.id || "-"}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sx={{
                                          visibility:
                                            indexY >= 1 ? "hidden" : "visible",
                                          padding: "12px",
                                        }}
                                      >
                                        <NumericFormat
                                          value={visibleRows[indexX].price}
                                          displayType={"text"}
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          prefix={"Rp. "}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sx={{
                                          visibility:
                                            indexY >= 1 ? "hidden" : "visible",
                                          padding: "12px",
                                        }}
                                      >
                                        <Typography>
                                          {visibleRows[indexX].status.label}
                                        </Typography>
                                        <Button
                                          sx={{
                                            color: "black",
                                            backgroundColor: "#FE8A01",
                                            border: "2px solid black",
                                            marginTop: "8px",
                                            padding: "12px",
                                            display:
                                              filteredOrderData[indexX].status
                                                .key === "PAID"
                                                ? "none"
                                                : "block",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "10px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Ingatkan Pelanggan
                                          </Typography>
                                        </Button>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sx={{
                                          visibility:
                                            indexY >= 1 ? "hidden" : "visible",
                                          padding: "12px",
                                        }}
                                      >
                                        <Typography>J&T Reg</Typography>
                                        <Typography
                                          sx={{
                                            color: "darkgray",
                                            fontSize: "10px",
                                          }}
                                        >
                                          Non COD
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        sx={{
                                          visibility:
                                            indexY >= 1 ? "hidden" : "visible",
                                          padding: "12px",
                                        }}
                                      >
                                        <Link
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation(); // Stop event from bubbling up to the Grid onClick handler
                                            handleCopy(
                                              "www.liquestore.com/#/register?orderid=" +
                                                filteredOrderData[indexX].id
                                            );
                                          }}
                                        >
                                          Copy Link
                                        </Link>
                                      </Grid>
                                    </Grid>
                                  ))}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>Nomor WA: </Typography>
                <TextField
                  value={orderDetail[0]?.phoneNumber || ""} // Safely access the first item
                  fullWidth
                  type="text"
                  autoComplete="off"
                  onChange={(e) => handlePhoneNumberChange(e)}
                  disabled
                ></TextField>
              </Grid>
              <FormControl
                sx={{
                  width: "100%",
                }}
              >
                {Array.isArray(orderDetail) && orderDetail.length > 0 ? (
                  orderDetail.map((detail, index) => (
                    <Grid
                      container
                      spacing={3}
                      sx={{
                        padding: "24px",
                        paddingRight: "0px",
                      }}
                      key={index}
                    >
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: "600",
                          }}
                        >
                          Item {index + 1}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>ID:</Typography>
                        <TextField
                          fullWidth
                          type="text"
                          autoComplete="off"
                          value={detail.id}
                          onValueChange={(values) =>
                            handleIdChange(values, index)
                          }
                          disabled
                        ></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Status:</Typography>
                        {statusDetail.length > 0 && (
                          <Select
                            labelId="status-select-label"
                            id={`status-select-${index}`}
                            value={statusDetail[index] || ""} // Use statusDetail state to track the value
                            onChange={(event) =>
                              handleChangeStatus(event, index)
                            } // Pass the index to handle the change
                            fullWidth
                          >
                            {statusOptions.map((statusOption) => (
                              <MenuItem
                                key={statusOption.key}
                                value={statusOption.key}
                              >
                                {statusOption.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          sx={{
                            width: 36,
                            height: 36,
                            borderRadius: "2px",
                            bgcolor: hexcode[index], // Use hexcode corresponding to this detail
                            outline: "2px solid black",
                            outlineOffset: "4px",
                          }}
                        ></Box>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography>
                          {detail.itemList[0].typeCode} {"("} {OrderidDetail}{" "}
                          {")"} - {detail.itemList[0].typeName} -{" "}
                          {detail.itemList[0].typeVariant}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12}>
                      <Typography>Kode Barang</Typography>
                      <Autocomplete
                        fullWidth
                        options={optTipe}
                        getOptionLabel={(option) =>
                          option.name + " - " + option.varian
                        }
                        renderInput={(params) => <TextField {...params} />}
                        value={
                          optTipe.find(
                            (option) =>
                              option.value === (TypeCode[index] || null) // Ensure typeCode[index] is used
                          ) || null
                        }
                        onChange={(event, newValue) =>
                          handleTypeChange(event, newValue, index)
                        }
                      />
                    </Grid> */}
                      <Grid item xs={12}>
                        <Typography>Kode Barang</Typography>
                        <Autocomplete
                          fullWidth
                          options={optTipe}
                          getOptionLabel={(option) =>
                            option.name + " - " + option.varian
                          }
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            optTipe.find(
                              (option) =>
                                option.value === (TypeCode[index] || null) // Ensure TypeCode is correctly matched with optTipe
                            ) || null
                          }
                          onChange={(event, newValue) =>
                            handleTypeChange(event, newValue, index)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>Price: </Typography>
                        <React.Fragment>
                          <NumericFormat
                            fullWidth
                            type="text"
                            value={detail.price || ""}
                            thousandSeparator="."
                            decimalSeparator=","
                            customInput={TextField}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Rp.{" "}
                                </InputAdornment>
                              ),
                            }}
                            onValueChange={(values) =>
                              handlePriceChange(values, index)
                            }
                            variant="outlined"
                          />
                        </React.Fragment>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography onClick={() => handleOpenDelete(index)}>
                          -Remove item
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography>No order details available.</Typography>
                )}
                <Grid
                  container
                  spacing={3}
                  sx={{
                    padding: "24px",
                    paddingRight: "0px",
                  }}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: "solid 2px black",
                      }}
                      onClick={(e) => {
                        handleSubmit(); // Call the second function with the event object
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={toMain}
                      fullWidth
                      style={{
                        backgroundColor: "grey",
                        color: "white",
                        border: "solid 2px black",
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          )}
        </TableContainer>
      </Box>
    </Box>
  );
}

export default PemesananPage2;
